import { LEGACY_AUTH_CONSTANT } from '@bvt-features/auth/redux';
import { GRIANA_CONSTANT } from './GRIANA_CONSTANT';
import { GRID_SIZE_CONSTANT } from '@bvt-features/genset/DensityAndGrid/constant/GRID_SIZE_CONSTANT';

/**
 * @copyright PT Bhumi Varta Technology
 * @author Renta <renta.yustika@bvarta.com>
 */

const initialState = {
  status_GET: 'IDLE',
  status_DETAIL: 'IDLE',
  status_GET_AREA: 'IDLE',
  status_GET_POI: 'IDLE',
  status_DETAIL_RIGHT: 'IDLE',
  status_GET_AREA_RIGHT: 'IDLE',
  status_GET_POI_RIGHT: 'IDLE',
  status_POST: 'IDLE',
  status_PUT: 'IDLE',
  status_DELETE: 'IDLE',
  status_ANALYZE: 'IDLE',
  status_GRID_SIZE: 'IDLE',
  message: '',
  data: {},
  formSaveProject: {},
  geojson: {},
  geojsonRight: {},
  gridDetail: {},
  gridDetailRight: {},
  poiCollections: [],
  poiCollectionsRight: [],
  whiteListGid: [],
  whiteListGidRight: [],
  acceptable: [
    'Acceptable 1',
    'Acceptable 2',
    'Acceptable 3',
    'Acceptable 4',
    'Unacceptable',
  ],
  acceptableRight: [
    'Acceptable 1',
    'Acceptable 2',
    'Acceptable 3',
    'Acceptable 4',
    'Unacceptable',
  ],
  step: 10,
  gid: '',
  gid_right: '',
  selected_gid: [],
  selected_gid_right: [],
  lastStep: 0,
  IS_SHOWN_ON_MAP: false,
  gridSize: {
    sizes: [...GRID_SIZE_CONSTANT],
    administrative_area: {}
  },
};

export function GRIANA_REDUCER(state = initialState, action) {
  let returnData = state;
  Object.values(GRIANA_CONSTANT).map((ctx) => {
    if (action.type === GRIANA_CONSTANT.RESET_STATUS) {
      returnData = {
        ...returnData,
        status_GET: 'IDLE',
        status_DETAIL: 'IDLE',
        status_GET_AREA: 'IDLE',
        status_GET_POI: 'IDLE',
        status_POST: 'IDLE',
        status_PUT: 'IDLE',
        status_DELETE: 'IDLE',
        status_ANALYZE: 'IDLE',
        status_DETAIL_RIGHT: 'IDLE',
        status_GET_AREA_RIGHT: 'IDLE',
        status_GET_POI_RIGHT: 'IDLE',
        message: ' ',
      };
    } else if (action.type === GRIANA_CONSTANT.RESET_ALL) {
      returnData = { ...initialState };
    } else if (action.type === LEGACY_AUTH_CONSTANT.UNAUTHORIZED) {
      returnData = { ...initialState };
    } else if (ctx === action.type && !ctx.includes('[NO_STATE_UPDATE]')) {
      returnData = { ...returnData, ...action.payload };
    }
    return null;
  });

  return returnData;
}
