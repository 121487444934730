export const GRID_SIZE_CONSTANT = [
  {
    value: 500,
    label: '500 M',
    status: false,
  },
  {
    value: 1000,
    label: '1 Km',
    status: false,
  },
  {
    value: 2000,
    label: '2 Km',
    status: false,
  },
  {
    value: 5000,
    label: '5 Km',
    status: false,
  },
  {
    value: 10000,
    label: '10 Km',
    status: false,
  },
];
